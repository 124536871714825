@import './src/scss/variables';

.week-pattern-cell {
  width: 160px;
  // background-color: yellow;
  display: flex;
  justify-content: space-between;

  &__day {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;

    &_active {
      background-color: $highlight-blue;
    }

    &_inactive {
      background-color: $gray-02;
    }
  }
}