@import 'variables';

button {
  &.ant-btn-primary {
    @include btn;
    background: $highlight-blue;
    box-shadow: $btn-primary-shadow;
    border-width: 2px;
  }

  &.ant-btn-link {
    &:disabled {
      color: $gray-02;

      img {
        filter: invert(50%) grayscale(100%);
        opacity: 0.6;
      }
    }
  }

  &.ant-btn-default {
    @include btn;

    color: $highlight-blue;
    border-width: 2px;
  }

}

.btn-form-action {
  margin-top: 16px;
  width: 100%;
}
