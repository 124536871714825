@import './src/scss/variables';

.table-container {
  max-width: 100%;
  padding-right: 10px;

  .select {
    width: 100%;
  }

  .table-wrapper {
    position: relative;

    .add-btn {
      position: absolute;
      bottom: -10px;
      right: -10px;
      height: 40px;
      width: 40px;
      background-color: $highlight-blue;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: drop-shadow(2px 6px 7px rgba(0, 0, 0, 0.1));
      cursor: pointer;

      &:focus,
      &:hover,
      &:active {
        background-color: $blue-01;
      }

      &.disabled {
        background-color: $gray-02;
        cursor: not-allowed;

        &:focus,
        &:hover {
          background-color: $gray-02;
          border-color: $gray-02;
        }
      }
    }
  }

  .table-selected-cell {
    color: #dc6c87;
  }

  .pagination-container {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .total-container {
      margin-top: -8px;

      @include total;
    }

    .wrapper {
      .button1 {
        color: $blue-01;
      }
    }

    .logo {
      @include bottom-logo;
    }
  }
}

.button1 {
  color: $blue-01;
}

.description-input {
  width: 200px;

  &-menu {
    max-width: 200px;
    white-space: break-spaces;
    word-wrap: anywhere;
    cursor: auto;
  }
}

.total-count-container {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: end;

  width: 90px;
  background-color: $blue-02;
  margin-left: auto;
  margin-right: 1px;
  color: #fff;
  padding: 0 8px;
  font-weight: 700;
  font-size: 10px;
  border-radius: 0 0 2px 2px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));

  .total-title {
    line-height: 1;
    padding: 6px 0 0;

    .total-selected-rows {
      display: flex;
      justify-content: flex-end;
      align-items: end;

      img {
        cursor: pointer;
        height: 12px;
      }
    }
  }

  .total-count {
    font-weight: 800;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 14px;
    }
  }
}
