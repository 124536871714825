@import '../../scss/variables';

.menu {
  padding: 0;
}

.icon-menu {
  height: 40px;
  width: 40px;
  background-color: $blue-02;
  mask-image: url('../../assets/icons/sidemenu.svg');
  mask-size: cover;
}

.btn-menu, .btn-menu:hover, .btn-menu:focus {
  height: 40px;
  width: 40px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.menu-item {
  min-width: 90px;
  height: 30px;
  font-size: 12px;
  padding: 0;
  list-style: none;
  background-color: #fff;

  > span {
    height: 100%;
  }

  .link {
    color: $black;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;

    &:hover {
      color: #fff;
      background-color: $highlight-blue;
    }
  }
}


.red{
  color: red; 
}

.grey {
  color:grey;
}
