@import 'variables';

.ant-table-wrapper {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));

  .ant-table {
    background-color: transparent;

    .ant-table-body {
      background-color: #fff;
    }
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
  }
}

.ant-table-container {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px 16px;
    font-size: 11px;
    height: 34px;
  }

  .ant-table-thead > tr > th {
    color: $black;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 8px;
    border-bottom: 1px solid $blue-02-at-30;
    background: transparent;
    padding: 10px 16px;
    height: 20px;
    max-height: 20px;
    white-space: nowrap;

    &::before {
      content: none !important;
    }
  }

  .ant-table-content {
    overflow-y: auto;
  }

  .ant-table-tbody {
    > tr > th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > tr.ant-table-row:hover > td {
      background: $highlight-blue-at-15;
    }

    > tr.ant-table-row {
      .ant-table-cell {
        .ant-btn.ant-btn-link.edit-cell {
          .inactive-status {
            color: $gray-00;
            text-decoration: none;
          }

          .green-status, .red-status, .orange-status {
            position: relative;
          }

          .red-status:after,
          .green-status:after,
          .orange-status:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 15px 15px;
            left: -15px;
            top: -10px;
            position: absolute;
          }

          .green-status:after {
            border-color: transparent transparent transparent $green;
          }
          .red-status:after {
            border-color: transparent transparent transparent $red;
          }
          .orange-status:after {
            border-color: transparent transparent transparent $orange;
          }
        }
      }
    }

    > tr.ant-table-row.table-selected-row {
      background-color: $highlight-blue-at-30;
      font-weight: 700;

      .ant-table-cell {
        .ant-btn.ant-btn-link.edit-cell {
          .link-cell-text {
            color: $highlight-blue;
          }
        }
      }
    }

    .ant-table-cell {
      label.ant-checkbox-wrapper {
        height: 20px;
      }

      .status-cell {
        font-size: 10px;
        font-weight: 700;
        background: #AEECDD;
        border-radius: 40px;
        padding: 2px 0;
        width: 50px;
        color: #45A58E;
        text-align: center;

        &-closed {
          background: #DC6C874D;
          color: #DC6C87;
        }
      }

      .ant-table-cell-content > .ant-btn.ant-btn-link.edit-cell,
      > .ant-btn.ant-btn-link {
        &.edit-cell {
          padding: 0;
          height: 20px;

          > img {
            height: 12px;
          }
        }

        > .link-cell-text {
          font-size: 11px;
          text-decoration: underline;
          font-weight: 700;

        }
      }
    }
  }

  .ant-dropdown-link {
    margin-left: 10px;
  }
}

.ant-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    font-size: 10px;
    font-weight: 800;
    height: 24px;
    min-width: 24px;
    border-radius: $default-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: transparent;
      color: $blue-02;

      &:hover {
        border-color: transparent;
        color: $blue-02;
      }
    }
  }

  .ant-pagination-item {
    border-color: transparent;

    a {
      font-weight: 800;
    }

    &:hover {
      border-color: $blue-02;
    }

    &.ant-pagination-item-active {
      border-color: $blue-02;
      background-color: $blue-02;
      filter: drop-shadow(0px 2px 4px $blue-02-at-30);

      a {
        color: $blue-03;
      }
    }
  }

  .ant-pagination-options {
    .ant-pagination-options-size-changer {
      .ant-select-selector {
        font-weight: 800;
        border-radius: $default-border-radius;
        height: 24px;
        font-size: 11px;
        display: flex;
        align-items: center;
        border-color: transparent;

        &:hover {
          border-color: $blue-02;
        }
      }
    }
  }

  .ant-pagination-total-text {
    width: max-content;
    font-weight: 800;
    font-size: 11px;
  }
}
.incomplete-data-icon {
  height: 14px;
}
