@import 'variables';

.ant-tabs {
  height: 100%;

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 10px 0;
  }

  &.ant-tabs-top > .ant-tabs-nav::before,
  &.ant-tabs-bottom > .ant-tabs-nav::before,
  &.ant-tabs-top > div > .ant-tabs-nav::before,
  &.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
  }

  .ant-tabs-tab {
    border-radius: $default-border-radius;
    padding: 4px 10px;
    font-weight: 800;
    font-size: 10px;
    color: $gray;

    &:hover {
      filter: drop-shadow(0 4px 5px $blue-02-at-30);
    }

    &.ant-tabs-tab-active {
      background: $blue-02;
      box-shadow: 0 0 4px $blue-01-at-40;
      color: #fff;

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }

    &.ant-tabs-tab-disabled {
      color: $tab-disabled;
    }
  }

  .ant-tabs-ink-bar {
    height: 0;
    background: transparent;
  }
}
