@import './src/scss/variables';

.hour-pattern-cell {
  width: 118px;
  height: 36px;
  display: flex;
  justify-content: space-between;

  &__day {
    width: 13px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    position: relative;

    &_closed {
      background-color: $gray-02;
    }
  }

  &__hour {
    height: 4px;
    background-color: $highlight-blue;
  }

  &__day-average {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
  }

  &__week-average {
    width: 13px;
    font-size: 10px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
