@import 'variables';

.ant-form-item-control {
  .ant-form-item-control-input {
    min-height: $form-item-height;

    .ant-select {
      & > .ant-select:not(.ant-select-customize-input),
      & > .ant-select-selector {
        @include form-element;
        align-items: center;

        input {
          height: $form-item-height;
        }

        & > .ant-select-selection-overflow {
          & > .ant-select-selection-overflow-item {
            margin-top: -6px;
            .ant-tag {
              @include tag-container;
            }
          }
        }
      }

      & > .ant-select-clear,
      & > .ant-select-arrow {
        right: 7px;
      }

      &.ant-select-disabled {
        & > .ant-select-selector {
          color: $form-text-disabled;
        }
      }
    }

    input,
    .ant-picker,
    .ant-input-affix-wrapper {
      @include form-element;

      .ant-picker-suffix {
        color: $blue-01;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;

      input {
        height: 22px;
      }
    }

    .ant-picker {
      width: 100%;
    }

    .ant-input[disabled] {
      color: $form-text-disabled;
      cursor: text;
    }
  }

  .ant-form-item-explain {
    &.ant-form-item-explain-error,
    &.ant-show-help-appear,
    &.ant-show-help-enter,
    &.ant-show-help-leave,
    &.ant-show-help-enter-active,
    &.ant-show-help-appear-active {
      font-size: 10px !important;
      color: $red !important;
      min-height: auto !important;
      height: 10px !important;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option {
    font-size: 12px;
    min-height: 20px;
    line-height: 15px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $highlight-blue-at-30;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panel {
      font-size: 12px;
    }
  }
}

.ant-divider {
  &.ant-divider-horizontal {
    margin: 14px 0;
  }
}

.label-text {
  @include label-text;
}

.ant-btn-link {
  &.staff-link-text {
    color: black;
    font-size: 14px;
    font-weight: 700;
    padding-top: 0;
    height: $form-item-height;

    &.selected {
      color: $highlight-blue;
    }

    span {
      text-decoration: underline;
    }
  }
}

.exception-details-form,
.manager-form,
.manager-details-form,
.profile-details-form,
.mvc-details-form,
.region-district-details-form,
.marketing-details-form,
.schedules-details-form,
.pro-service-details-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;

  > div {
    width: 100%;
  }

  &-zip,
  &-city,
  &-state {
    width: 30% !important;
    margin-right: 8px !important;
  }

  .ant-form-item {
    margin-bottom: 0;

    &.checkbox-item {
      width: 70px;
      display: inline-block;
    }

    .ant-form-item {
      &-label {
        padding-bottom: 0;

        > label {
          @include label-text;
          display: flex;
          align-items: flex-end;
          padding-top: 6px;
        }
      }
    }
  }

  .assigned-text {
    color: $blue-01;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
  }
}

.schedules-details-form {
  .ant-form-item {
    .ant-form-item-control {
      .ant-form-item-control-input-content {
        font-size: 10px;

        .ant-checkbox-wrapper {
          font-size: 10px;
          font-weight: 800;
          align-items: center;
          margin-left: 15px;

          .ant-checkbox + span {
            padding-top: 6px;
            padding-left: 6px;
            line-height: 1;
          }
        }

        .ant-input.time-input {
          width: 45px;
          height: 20px;
          border-radius: 10px;
          font-size: 10px;
          padding: 0 7px;
          margin-right: 5px;
        }
      }

      // .ant-form-item-explain {
      //   &.ant-form-item-explain-error,
      //   &.ant-show-help-appear,
      //   &.ant-show-help-enter,
      //   &.ant-show-help-leave,
      //   &.ant-show-help-enter-active,
      //   &.ant-show-help-appear-active {
      //     position: absolute;
      //     top: 22px;
      //     min-width: 110px;
      //     left: 0;
      //     font-size: 10px !important;
      //     color: $red !important;
      //     min-height: auto !important;
      //     height: 10px !important;
      //   }
      // }
    }
  }

  button.ant-switch {
    background-color: #fff;
    border: 1px solid $blue-01-at-30;
    height: 20px;
    min-width: 35px;
    background-image: none;

    &.ant-switch-checked {
      background-color: $blue-01-at-30;
      color: $blue-01;

      .ant-switch-handle {
        left: calc(100% - 14px - 2px);
      }

      .ant-switch-inner {
        margin: 0 18px 0 4px;
      }
    }

    > .ant-switch-handle {
      width: 14px;
      height: 14px;

      &::before {
        background-color: $blue-02;
      }
    }

    > .ant-switch-inner {
      color: $blue-01;
      font-size: 8px;
      font-weight: 800;
      line-height: 18px;
      margin: 0 4px 0 18px;
    }
  }

  .schedule-label {
    @include label-text;
    margin-top: 10px;
  }

  .schedule-time-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

    .time-divider {
      margin: 0 8px;
    }
  }
}

.manager-details-form-header {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  @include header;

  button.ant-btn {
    border: none;
    padding: 0;
    background: none;
    font-weight: 800;

    :focus,
    :hover {
      background: none;
    }

    span {
      font-size: 11px;
    }
  }
}

.search-container {
  // background: linear-gradient(91.11deg, #1089bc 0%, #70c6ea 100%, #70c6ea 100%);
  background: linear-gradient(92deg, rgba(177, 138, 100, 62%), #84c3e6 100%);
  border-radius: $default-border-radius;
  justify-content: space-between;
  align-items: center;
  display: flex;
  min-height: 60px;
  padding: 10px 10px 0 10px;
  margin: 0 10px 20px 0;

  .ant-form {
    display: flex;
    align-items: flex-end;
    .ant-form-item {
      margin-right: 15px;
      margin-bottom: 13px;

      &.search-action {
        min-width: 150px;
        max-width: 220px;

        .ant-select {
          .ant-select-selection-overflow {
            .ant-select-selection-overflow-item {
              .ant-select-selection-item {
                margin-top: 6px;
              }
            }
          }
        }
      }

      &.input-field {
        min-width: 145px;
        max-width: 220px;

        &_name {
          min-width: 170px;
        }

        &_employee-id,
        &_office-number,
        &_errors {
          min-width: 90px;
        }

        &_date-range {
          min-width: 220px;
        }
      }

      &.input-field-number {
        min-width: 50px;
        width: 100px;
      }

      &.input-field-short {
        width: 80px;
        margin-bottom: 0;
        margin-right: 0;
        display: inline-block;

        &_margin {
          margin-right: 5px;
        }
      }

      &.date-range-picker {
        min-width: 145px;
        max-width: 240px;
      }

      &.search-btn {
        margin-bottom: 10px;

        .ant-form-item-control-input-content {
          display: flex;
          flex-wrap: nowrap;
        }
      }

      &.active-only {
        margin-right: 35px;
        margin-left: auto;
      }

      &.status-type {
        min-width: 225px;
      }

      &.region-field {
        min-width: 200px;
      }

      &.district-field {
        min-width: 200px;
      }

      > .ant-form-item-row .ant-form-item {
        &-label {
          padding-bottom: 0;

          > label {
            @include label-text;
            color: #fff;
            padding-bottom: 2px;
            display: flex;
          }
        }
      }

      .ant-btn {
        @include btn;
        background-color: $blue-01;
        border-color: $blue-01;
      }

      .ant-form-item-control {
        .ant-form-item-explain {
          display: none;
        }
      }

      .ant-input-affix-wrapper {
        padding-left: 0;
        padding-right: 5px;
      }

      input {
        padding-left: 8px;
        padding-right: 2px;
      }
    }
  }

  .archived-switch {
    margin-top: 25x;

    > label {
      ///@include label-text;
      color: #fff;
      padding-bottom: 5px;
    }
    .ant-switch {
      margin-left: 10px;
      margin-right: 50px;
    }
  }
  // .ant-switch {
  //   margin-top: 29px;

  // }
}

.profile-menu {
  font-size: 12px;

  .username {
    padding: 10px 15px 0;
  }

  .ant-dropdown-menu-item {
    padding: 0;

    .ant-btn {
      font-size: 12px;
      font-weight: 700;

      span {
        text-decoration: underline;
      }
    }
  }
}

.drawer-panel {
  .ant-drawer-content-wrapper {
    width: 300px;

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.pace-drawer-panel {
  .ant-drawer-content-wrapper {
    width: 300px;

    .ant-drawer-header {
      background-color: $gray-02;
      padding: 14px 20px;

      .ant-drawer-header-title {
        flex-direction: row-reverse;

        .ant-drawer-close {
          img {
            height: 14px;
          }
        }

        .ant-drawer-title {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    .ant-drawer-body {
      padding: 0;
      .header {
        height: 50px;
        padding: 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $gray-02;
        color: $blue-01;

        &__text {
          font-weight: 600;
        }

        &__close {
          font-weight: 700;
          text-decoration: none;
          font-size: 22px;
        }
      }

      .subheader {
        margin: 20px;
        border-bottom: 2px solid $gray-02;
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;

        &__text {
          display: flex;
          font-weight: 700;
        }

        &__id {
          margin-left: 20px;
        }

        &__office {
          margin-left: 20px;
        }

        &__office-name {
          margin-left: 20px;
        }

        &__date {
          font-size: 11px;
          font-weight: bold;
        }
      }

      .week {
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        padding-bottom: 30px;

        &__pattern-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__week-number {
          margin-left: 20px;
          font-size: 11px;
          font-weight: bold;
        }

        &__wrapper {
          display: flex;
          margin-top: 20px;
        }

        &__column-title {
          height: 18px;
          font-weight: bold;
        }

        &__column-value {
          height: 18px;

          &_grayed-out {
            color: #a9a9a9;
          }
        }

        &__day-column {
          width: 95px;
        }

        &__date-column {
          width: 85px;
        }

        &__time-column {
          white-space: nowrap;
          background-color: pink;
        }

        &__assign-btn-wrap {
          padding-bottom: 15px;
          margin-top: 15px;
          margin-bottom: 30px;
          border-bottom: 2px solid $gray-02;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__month-assign-btn-wrap {
          padding-bottom: 15px;
          margin-top: 15px;
          margin-bottom: 30px;
          // border-bottom: 2px solid $gray-02;
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          align-items: center;
        }
      }

      .week-grid {
        margin-top: 20px;

        th.ant-table-cell {
          font-size: 14px;
          font-weight: bold;
          text-transform: capitalize;
          padding: 0 0 0 3px;
          text-align: left;
          background-color: white;
        }

        td.ant-table-cell {
          height: unset;
          font-size: 14px;
          padding: 0 0 0 3px;
          background-color: white;

          .grayed-out {
            color: #a9a9a9;
          }
          .range0-template-text {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.ant-modal > .ant-modal-content {
  border-radius: 8px;
  width: 320px;

  > .ant-modal-close {
    .ant-modal-close-x {
      width: 40px;
      height: 32px;
      line-height: 32px;
      color: $blue-02;
      font-weight: 800;
      font-size: 14px;
    }
  }

  > .ant-modal-header {
    padding: 4px 20px 2px;

    background: #70c6ea4d;
    border-bottom: 2px solid #70c6ea4d;

    .ant-modal-title {
      font-size: 12px;
      font-weight: 800;
      color: $blue-01;
    }
  }

  > .ant-modal-body {
    padding: 5px 20px 20px 20px;
  }
}

.tag-container > .ant-tag {
  @include tag-container;
}

.details-panel {
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;

  &-btn {
    color: #fff;
    background-color: $blue-01;
    border-radius: 4px;
    font-weight: bold;
    font-size: 10px;
    height: $form-item-height;
    align-self: flex-end;
    margin: 15px 20px 15px;
    box-shadow: $btn-primary-shadow;
  }

  .details-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    > span {
      font-size: 12px;
      font-weight: 700;
      color: $blue-01;
      padding-left: 15px;
    }
  }
}
